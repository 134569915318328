import React, { PropsWithChildren, useEffect, Children } from 'react'

import { IProduct, ProductSoldOutStatus } from '@typesApp/product'
import { useTranslation } from 'next-i18next'
import CurrencyService from '@services/CurrencyService'
import { getProductAlgoliaPrice } from '@foundation/algolia/algoliaPrice'
import { useSite } from '@foundation/hooks/useSite'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { ProductPriceAlgolia } from '@views/ProductDetails/components/ProductPriceAlgolia'
import { StyledFormControlLabel, StyledRadioGroup } from '@components/UI'
import { useDispatch, useSelector } from 'react-redux'
import { getNormalizedProductType } from '@utils/productAttributes'
import {
  gestDiscountFromFromMarketingSpot,
  getOrderItemSubscriptionInfo,
  getSubscribedItems,
  parseRecurrencyString,
  stringifySubscriptionRecurrency,
} from '../helpers/subscriptionHelpers'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import {
  useDeleteSubscriptionInfoMutation,
  useGetSubscriptionsTermsAndConditionsQuery,
} from '@features/subscription/query'
import { orderItemsSelector } from '@features/order/selector'
import { RecurrencySelect } from '../cart/components/RecurrencySelect'
import { SUBSCRIPTION_DISCOUNT_PERCENT_VALUE, SUBSCRIPTION_FALLBACK_RECURRENCY } from '../constants'
import { SET_SUBSCRIPTION_CONFIG_ACTION } from '@redux/actions/subscription'
import { SubscriptionStyledRadio } from './StyledRadio'
import { useTheme } from '@mui/material/styles'
import { isMtoProduct } from '@utils/product'
import { PRODUCT_SOLDOUT_STATUS } from '@constants/product'
import { toNumber } from 'lodash-es'
import { ProductPriceDiscountBox, ProductPriceLabel } from '@views/ProductDetails/components/ProductPrice.style'
import { Adjustment } from '@typesApp/order'
import { ADJUSTMENT_USAGE } from '@constants/common'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import storeUtil from '@utils/storeUtil'
import styled from '@mui/material/styles/styled'
import styles from './styles/SubscriptionPDP.module.scss'
import clsx from 'clsx'
import { subscriptionDiscountSelector } from '@redux/selectors/site'

const FormControlLabel = styled(StyledFormControlLabel, {
  name: 'Subscription',
  slot: 'FormControlLabel',
})(({ theme }) => ({
  '.MuiFormControlLabel-label': {
    color: theme.palette.text.dark.primary,
  },
}))

export type SubscriptionSelectionOptionProps = {
  currentProduct: IProduct
  productQuantity: number
  isLoading: boolean
  onSubscriptionSelected: (isSelected: boolean) => void
}
const ONE_TIME_PURCHASE_KEY = 'onetimepurchase'
const SUBSCRIPTION_PURCHASE_KEY = 'subscription'

type SubscriptionSelectionProps = {
  currentProduct: IProduct
  productQuantity: number
  isLoading: boolean
  soldOutStatus: ProductSoldOutStatus
  isEditingContactLens?: boolean
  adjustments?: Adjustment[]
  setAdjustments?: (adjustments: Adjustment[]) => void
}

export const SubscriptionSelectionOption: React.FC<PropsWithChildren<SubscriptionSelectionProps>> = props => {
  const {
    currentProduct,
    productQuantity,
    isLoading,
    soldOutStatus,
    isEditingContactLens,
    adjustments,
    setAdjustments,
    children,
  } = props
  const { mySite } = useSite()
  const { t: translate } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const customerSegments = useCustomerSegmentsUtil()
  const subscriptionConfig = useSelector(subscriptionConfigSelector)
  const subscriptionDiscountFromConfig = useSelector(subscriptionDiscountSelector)
  const productType = getNormalizedProductType(currentProduct)
  const [deleteSubscriptionInfo] = useDeleteSubscriptionInfoMutation()
  const partNumber = currentProduct?.partNumber || ''
  const algoliaPrices = getProductAlgoliaPrice(customerSegments, currentProduct)
  const boxPrice = Object.values(algoliaPrices).find(val => val)
  const hasBoxPriceDiscount = (boxPrice?.amountOfDiscount ?? 0) > 0
  const orderItems = useSelector(orderItemsSelector)
  const isProductSubscriptionAllowed = subscriptionConfig.allowedProducts?.includes(productType)

  const isMTO = storeUtil.isMTOEnabled(mySite) && isMtoProduct(currentProduct)
  const showSubscriptionSection =
    !isMTO &&
    subscriptionConfig.enabled &&
    subscriptionConfig.allowedProducts?.includes(productType) &&
    soldOutStatus !== PRODUCT_SOLDOUT_STATUS.SOLDOUT &&
    soldOutStatus !== PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK
  const [quantitySelect, errorsContainer] = Children.toArray(children)

  const onSubscriptionChange = (active: boolean, interval?: string) => {
    if (isProductSubscriptionAllowed) {
      const recurrency = interval ? parseRecurrencyString(interval) : subscriptionInfo?.recurrency

      dispatch(
        SET_SUBSCRIPTION_CONFIG_ACTION({
          item: {
            identifier: currentProduct.partNumber,
            active: active && isProductSubscriptionAllowed,
            recurrency: recurrency ?? parseRecurrencyString(SUBSCRIPTION_FALLBACK_RECURRENCY),
          },
        })
      )

      if (!active) {
        subscribedItemsInCart?.forEach(item => {
          if (item.partNumber === currentProduct.partNumber) {
            deleteSubscriptionInfo({
              storeId: mySite.storeID,
              orderItemId: item.orderItemId,
            })
          }
        })
      }
    }
  }

  let subscriptionInfo = subscriptionConfig.subscribedItems?.find(info => info.identifier === partNumber)
  if (!subscriptionInfo && isEditingContactLens) {
    const orderItem = orderItems.find(item => item.productId === currentProduct.uniqueID)
    subscriptionInfo = orderItem ? getOrderItemSubscriptionInfo(orderItem) : undefined
    if (subscriptionInfo) {
      onSubscriptionChange(
        subscriptionInfo?.active || false,
        `${subscriptionInfo?.recurrency?.value}|${subscriptionInfo?.recurrency?.interval}`
      )
    }
  }

  const defaultPurchaseValue = subscriptionInfo?.active ? SUBSCRIPTION_PURCHASE_KEY : ONE_TIME_PURCHASE_KEY

  const subscribedItemsInCart = getSubscribedItems(orderItems)
  const { data: eSpotData } = useGetSubscriptionsTermsAndConditionsQuery({ storeId: mySite.storeID })
  const subscriptionDiscounts = gestDiscountFromFromMarketingSpot(eSpotData)

  const discountPercent = subscriptionDiscountFromConfig ? toNumber(subscriptionDiscountFromConfig) : (subscriptionDiscounts?.discountPercentValue ?? SUBSCRIPTION_DISCOUNT_PERCENT_VALUE)

  useEffect(() => {
    if (subscriptionInfo?.active && !isEditingContactLens) {
      dispatch(
        SET_SUBSCRIPTION_CONFIG_ACTION({
          item: {
            identifier: currentProduct.partNumber,
            active: false,
            recurrency: parseRecurrencyString(SUBSCRIPTION_FALLBACK_RECURRENCY),
          },
        })
      )
    }
  }, [])

  useEffect(() => {
    const listPrice = toNumber(boxPrice?.listPrice || 0)
    const offerPrice = toNumber(boxPrice?.offerPrice || 0)
    const amount = -((listPrice * discountPercent) - (listPrice - offerPrice))
    setAdjustments &&
      setAdjustments(
        defaultPurchaseValue == SUBSCRIPTION_PURCHASE_KEY
          ? [
              {
                amount: `${amount}`,
                code: 'SUBSCRIPTION_PURCHASE',
                currency: boxPrice?.currency || '',
                description: 'SUBSCRIPTION_PROMO',
                displayLevel: 'Order',
                descriptionLanguage: mySite.locale,
                language: mySite.locale,
                usage: ADJUSTMENT_USAGE.DISCOUNT,
              },
            ]
          : []
      )
  }, [defaultPurchaseValue])

  return (
    <>
      {(showSubscriptionSection && (
        <>
          <StyledRadioGroup value={defaultPurchaseValue}>
            <div className={styles.selectionTitle}>{translate('Subscriptions.Header.Title')}</div>
            <div
              className={clsx(
                styles.pdpSubscriptionSection,
                defaultPurchaseValue === ONE_TIME_PURCHASE_KEY && styles.selected
              )}
              style={{
                borderBottom: `solid ${theme.spacing(0.5)} ${
                  defaultPurchaseValue === ONE_TIME_PURCHASE_KEY ? theme.palette.custom.boulder : 'transparent'
                }`,
              }}
            >
              <div className={styles.headerSection}>
                <FormControlLabel
                  data-testid={'oneTimePurchase'}
                  key={ONE_TIME_PURCHASE_KEY}
                  value={ONE_TIME_PURCHASE_KEY}
                  control={<SubscriptionStyledRadio />}
                  label={translate('Subscriptions.Header.OneTimeBuy')}
                  onChange={() =>
                    onSubscriptionChange(false, stringifySubscriptionRecurrency(subscriptionInfo?.recurrency))
                  }
                />
                <div className={clsx(styles.pricePerBoxWrapper, styles.pdp)}>
                  <span>{translate(['Subscriptions.Labels.PerBox', 'Per box '])}</span>
                  <span style={{ textDecoration: hasBoxPriceDiscount ? 'line-through' : 'none' }}>
                    {CurrencyService.getFormattedPrice(mySite.locale, boxPrice?.currency || '', boxPrice?.listPrice)}
                  </span>
                  {hasBoxPriceDiscount && (
                    <span>
                      {CurrencyService.getFormattedPrice(mySite.locale, boxPrice?.currency || '', boxPrice?.offerPrice)}
                    </span>
                  )}
                </div>
                {defaultPurchaseValue === ONE_TIME_PURCHASE_KEY && (
                  <>
                    <div className={styles.itemSeparator} />
                    <span className={styles.quantityLabel}>
                      {translate(['Subscriptions.Labels.SelectQuantity', 'Select your quantity'])}
                    </span>
                    {quantitySelect}
                  </>
                )}
              </div>
            </div>
            <div
              className={clsx(
                styles.pdpSubscriptionSection,
                defaultPurchaseValue === SUBSCRIPTION_PURCHASE_KEY && styles.selected
              )}
              data-element-id="Prods_Subscription"
              style={{
                borderTop: `solid ${theme.spacing(0.5)} ${
                  defaultPurchaseValue === SUBSCRIPTION_PURCHASE_KEY ? theme.palette.custom.boulder : 'transparent'
                }`,
              }}
            >
              <div className={styles.headerSection}>
                <FormControlLabel
                  data-testid={'subscriptionPurchase'}
                  key={SUBSCRIPTION_PURCHASE_KEY}
                  value={SUBSCRIPTION_PURCHASE_KEY}
                  control={<SubscriptionStyledRadio />}
                  label={translate('Subscriptions.Header.Subscription')}
                  onChange={() => onSubscriptionChange(true)}
                />
                <div className={clsx(styles.pricePerBoxWrapper, styles.pdp)}>
                  <span>{translate('Subscriptions.Labels.PerBox')}</span>
                  <span style={{ textDecoration: 'line-through' }}>
                    {CurrencyService.getFormattedPrice(
                      mySite.locale,
                      boxPrice?.currency ?? '',
                      boxPrice?.listPrice ?? 0
                    )}
                  </span>
                  <span style={{ fontWeight: 700 }}>
                    {CurrencyService.getFormattedPrice(
                      mySite.locale,
                      boxPrice?.currency ?? '',
                      `${toNumber(boxPrice?.listPrice || 0) * (1 - discountPercent)}`
                    )}
                  </span>
                  <ProductPriceDiscountBox isPDP={true}>
                    {translate('ProductTile.Labels.PercentageOff', {
                      amount: discountPercent * 100,
                    })}
                  </ProductPriceDiscountBox>
                </div>
              </div>
              <div className={styles.itemSeparator} />
              <>
                <div className={styles.benefitsSection}>
                  <div>
                    <SVGIcon library="misc3" name="subscription-free-delivery" size={16} />
                    {translate('Subscriptions.Advantages.FreeDelivery')}
                  </div>
                  <div>
                    <SVGIcon library="misc3" name="subscription-auto-delivery" size={16} />
                    {translate('Subscriptions.Advantages.AutomaticDelivery')}
                  </div>
                  <div>
                    <SVGIcon library="misc3" name="subscription-calendar" size={16} />
                    {translate('Subscriptions.Advantages.UnsubscribeAnytime')}
                  </div>
                </div>
              </>
              {defaultPurchaseValue === SUBSCRIPTION_PURCHASE_KEY && (
                <>
                  <div className={styles.itemSeparator} />
                  <RecurrencySelect
                    data-testid={'subscriptionRecurrency'}
                    defaultValue={
                      subscriptionInfo
                        ? `${subscriptionInfo?.recurrency?.value}|${subscriptionInfo?.recurrency?.interval}`
                        : SUBSCRIPTION_FALLBACK_RECURRENCY
                    }
                    onChange={value => onSubscriptionChange(subscriptionInfo?.active || false, value)}
                    showStacked
                  />
                  <div className={styles.itemSeparator} />
                  {quantitySelect}
                </>
              )}
            </div>

            <div className={styles.itemSeparator} />
          </StyledRadioGroup>
          {errorsContainer}
          <ProductPriceAlgolia
            adjustments={adjustments}
            isLoading={isLoading}
            totalBoxes={productQuantity}
            soldOutStatus={soldOutStatus}
          />
        </>
      )) || (
        <>
          {soldOutStatus !== PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK &&
            soldOutStatus !== PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON && (
              <div className={styles.priceLabelWrapper}>
                <ProductPriceLabel isBold={true} isStickyBar={false}>
                  {translate('Labels.Price')}
                </ProductPriceLabel>
                <div className={clsx(styles.pricePerBoxWrapper, styles.pdp)}>
                  <span style={{ textDecoration: hasBoxPriceDiscount ? 'line-through' : 'none' }}>
                    {CurrencyService.getFormattedPrice(mySite.locale, boxPrice?.currency || '', boxPrice?.listPrice)}
                  </span>
                  {hasBoxPriceDiscount && (
                    <span>
                      {CurrencyService.getFormattedPrice(mySite.locale, boxPrice?.currency || '', boxPrice?.offerPrice)}
                    </span>
                  )}
                  {translate(['OrderRecap.Labels.PerBox', 'per box'])}
                </div>
              </div>
            )}
          {errorsContainer}
          <ProductPriceAlgolia isLoading={isLoading} totalBoxes={productQuantity} soldOutStatus={soldOutStatus} />
        </>
      )}
    </>
  )
}
