import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { ContactLensesSelect } from '../ContactLensesSelect'
import { StyledCheckbox, StyledFormControlLabel } from '../../../../components/UI'
import { toggleAddToCart, updateContactLensData } from '../../../../features/product/slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  browserInUse,
  updateLensRangeFieldsBySelectedValue,
  loadValidProductLensRangeValues,
} from '../../../../utils/common'

import {
  EyeContactLensAttribute,
  IProduct,
  ContactLensesData,
  EyeClFieldConfig,
  SupplyData,
  ProductSoldOutStatus,
  EyeContactLensOverrideMap,
  EyeClFieldConfigMap,
} from '../../../../types/product'
import Log from '../../../../services/Log'
import {
  addContactLensesToCartErrorSelector,
  addSubscriptionToCartErrorSelector,
  currentProductBundleSelector,
} from '../../../../features/product/selector'
import { CL_EYE_LEFT, CL_EYE_RIGHT, PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS } from '../../../../constants/product'
import { forEach, toNumber } from 'lodash-es'
import { setAddToCartBusy } from '../../../../features/cartui/cartuiSlice'
import { useSite } from '../../../../foundation/hooks/useSite'
import WithLoading from '../../../../components/with-loading/WithLoading'
import { isFetchingSelector } from '../../../../features/order/selector'
import { CONTACT_LENS_DEFAULT_QUANTITY } from '@constants/common'
import { RightColumnErrorContainer } from '@views/ProductDetails/ProductDetails.style'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import { SubscriptionSelectionOption } from '@views/Subscription/pdp/SubscriptionPDP'
import { useContactLensSelection } from './useContactLensSelection'
import { isMtoProduct } from '@utils/product'
import { Adjustment } from '@typesApp/order'
import storeUtil from '@utils/storeUtil'
import clsx from 'clsx'
import styles from './styles/ContactLensSelection.module.scss'
import { PrescriptionDetailsForm } from '@components/PrescriptionDetailsForm'
import { FormProvider, useForm } from 'react-hook-form'
import { useYupValidationResolver } from '@utils/validationResolver'
import {
  ContactLensFormFields,
  ContactLensValidationSchema,
} from '@views/ProductDetails/components/ContactLensSelection/contactLens.schema'
import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'
import { format } from 'date-fns'

export const QUANTITY_FIELD_ID = 'quantity'
export const COLOR_FIELD_ID = 'x_color'

export const ContactLensSelection: React.FC<{
  pdpData?: IProduct | null
  soldOutStatus: ProductSoldOutStatus
  pdpdataLoading: boolean
  currentProduct: IProduct
  ctaRef: React.RefObject<HTMLDivElement>
  stickyBarCtaRef: React.RefObject<HTMLDivElement>
  setTotalBoxes?: (e: number) => void
  isEditingContactLens?: boolean
  supplyData: SupplyData[]
  isLoading: boolean
  adjustments?: Adjustment[]
  setAdjustments?: (adjustments: Adjustment[]) => void
}> = ({
  ctaRef,
  soldOutStatus,
  stickyBarCtaRef,
  currentProduct,
  pdpData,
  isLoading,
  setTotalBoxes,
  isEditingContactLens,
  supplyData,
  adjustments,
  setAdjustments,
}) => {
  const rxTaxExemption = useRxTaxExemption()
  const validationSchema = useMemo(() => {
    if (rxTaxExemption.isEnabled) {
      return ContactLensValidationSchema
    }

    return null
  }, [rxTaxExemption.isEnabled])

  const formMethods = useForm<ContactLensFormFields>({
    defaultValues: {
      doctor: '',
    },
    mode: 'onChange',
    resolver: useYupValidationResolver(validationSchema),
  })

  const issueDate = formMethods.watch('issue')
  const doctorName = formMethods.watch('doctor')

  const prescriptionDetailsIsValid = rxTaxExemption.isEnabled && formMethods.formState.isValid
  const [showPrescriptionsDetailsError, setShowPrescriptionsDetailsError] = useState(
    (rxTaxExemption.isEnabled && !prescriptionDetailsIsValid && formMethods.formState.isDirty) ||
      Object.keys(formMethods.formState.errors).length > 0
  )

  const { t: translate } = useTranslation()
  const dataRef = useRef<ContactLensesData>()
  const { contactLensData } = useSelector(currentProductBundleSelector)
  const [filteredContactLensFieldsConfig, setFilteredContactLensFieldsConfig] = useState<EyeClFieldConfigMap | null>(
    null
  )
  const dispatch = useDispatch()
  const addContactLensesToCartError = useSelector(addContactLensesToCartErrorSelector)
  const addSubscriptionToCartError = useSelector(addSubscriptionToCartErrorSelector)
  const [defaultClValues, setDefaultClValues] = useState<EyeContactLensOverrideMap>()
  const { mySite } = useSite()
  const subscriptionConfig = useSelector(subscriptionConfigSelector)
  const isMTO = storeUtil.isMTOEnabled(mySite) && isMtoProduct(currentProduct)
  const showSubscriptionSection =
    !isMTO &&
    subscriptionConfig.enabled &&
    subscriptionConfig.allowedProducts?.includes(PRODUCT_TYPES_KEYS.CONTACT_LENSES)
  const contactLensesSelection = useContactLensSelection(currentProduct, pdpData, defaultClValues)
  const {
    formatDataForCart,
    defaultEmptyFields,
    contactLensesData,
    setContactLensesData,
    contactLensFieldsConfig,
    fieldsHaveErrors,
    setBothEyesDisabled,
    bothEyesDisabled,
    areBothEyesDisabled,
    fieldsAreNotValid,
    getTotalBoxesCount,
    isColorEmpty,
  } = contactLensesSelection

  useEffect(() => {
    if (contactLensFieldsConfig) {
      setFilteredContactLensFieldsConfig(loadValidProductLensRangeValues(contactLensFieldsConfig, currentProduct))
    }
  }, [contactLensFieldsConfig])

  const isColorContacts =
    (filteredContactLensFieldsConfig &&
      filteredContactLensFieldsConfig[CL_EYE_LEFT]?.find(c => c.id === COLOR_FIELD_ID)?.options?.length! > 1) ||
    false

  const isFetchingCart = useSelector(isFetchingSelector)

  const labels = filteredContactLensFieldsConfig
    ? filteredContactLensFieldsConfig[CL_EYE_LEFT]?.filter(
        fieldConf =>
          (fieldConf.visible || false) && fieldConf.id !== QUANTITY_FIELD_ID && fieldConf.id !== COLOR_FIELD_ID
      ).map(fieldConf => {
        return (
          <span className={styles.contactLensesDataLabel} key={`${fieldConf.id}_${fieldConf.label}`}>
            <WithLoading isLoading={isLoading}>{fieldConf.label}</WithLoading>{' '}
          </span>
        )
      })
    : null

  const updateFieldValue = (
    eye: string,
    id: EyeContactLensAttribute,
    val?: string | null
  ): Promise<ContactLensesData> => {
    return new Promise(resolve => {
      const eyeContactLensesData = !!contactLensesData ? contactLensesData[eye] : null

      const data: ContactLensesData = {
        ...contactLensesData,
        [eye]: {
          ...eyeContactLensesData,
          [id]: val?.toString() || '',
        },
      }
      resolve(data)
    })
  }

  const updateFieldsStatus = (
    eye: string,
    id: string,
    fieldsContactLensesData: ContactLensesData,
    valid?: boolean,
    val?: string | null
  ): ContactLensesData => {
    let fieldsData: ContactLensesData = {}
    try {
      const fieldStatus = fieldsContactLensesData[eye].fieldsStatus
      const { errors, emptyFields } = fieldStatus || {}
      const updatedEmptyFields =
        val !== null
          ? emptyFields?.filter(field => field !== id)
          : !emptyFields?.includes(id)
            ? [...(emptyFields || []), id]
            : emptyFields

      const updatedErrors =
        !valid || updatedEmptyFields?.includes(id)
          ? !fieldStatus?.errors?.includes(id)
            ? [...(fieldStatus?.errors || []), id]
            : fieldStatus?.errors
          : errors?.filter(error => error !== id)

      fieldsData = {
        ...fieldsContactLensesData,
        [eye]: {
          ...fieldsContactLensesData[eye],
          x_productId: pdpData?.id,
          fieldsStatus: {
            ...fieldStatus,
            errors: updatedErrors,
            emptyFields: updatedEmptyFields,
            valid: updatedEmptyFields && updatedEmptyFields.length <= 0 && updatedErrors && updatedErrors.length <= 0,
          },
        },
      }
      return fieldsData
    } catch (e) {
      Log.error('Error updating field status: ' + e)
    }

    return fieldsData
  }

  const validateAllFields = (): Promise<ContactLensesData> => {
    let updatedState: ContactLensesData = { ...contactLensesData }
    Object.keys(updatedState).map(eye => {
      const eyeStatus = { ...updatedState[eye] }
      Object.keys(eyeStatus)
        .map(fieldId => {
          const { isValid } = validateField(eye, fieldId, updatedState)
          return { isValid, eye, fieldId }
        })
        .map(item => {
          const { isValid, eye, fieldId } = item
          const data = updateFieldsStatus(eye, fieldId, updatedState, isValid)
          updatedState[eye] = data[eye]
        })
    })
    return new Promise(resolve => {
      resolve(updatedState)
    })
  }

  const validateField = (eye: string, fieldId: string, fielsData: ContactLensesData): { isValid: boolean } => {
    const eyeContactLensesData = !!fielsData ? fielsData[eye] : null
    const fieldIsRequired = filteredContactLensFieldsConfig
      ? filteredContactLensFieldsConfig[eye]?.find(field => field.id === fieldId)?.required
      : false

    const fieldHasError = !!eyeContactLensesData
      ? fieldIsRequired &&
        (eyeContactLensesData[fieldId] === '' ||
          (fieldId == QUANTITY_FIELD_ID && eyeContactLensesData[fieldId] === CONTACT_LENS_DEFAULT_QUANTITY)) &&
        eyeContactLensesData.fieldsStatus?.enabled
      : false

    return { isValid: !fieldHasError || false }
  }

  const onEyeCheckBoxValueChanged = (value: boolean, eye: string): Promise<ContactLensesData> => {
    return new Promise(resolve => {
      const data: ContactLensesData = {
        ...contactLensesData,
        [eye]: {
          ...contactLensesData[eye],
          fieldsStatus: {
            emptyFields: defaultEmptyFields[eye] || [],
            dirtyFields: [],
            errors: [],
            enabled: value,
            valid: false,
          },
        },
      }
      resolve(data)
    })
  }
  const renderField = (
    key: string,
    id: EyeContactLensAttribute,
    eye: string,
    onClSelectChanged: (eye: string, id: EyeContactLensAttribute, val?: string | null) => Promise<ContactLensesData>,
    isSelect: boolean,
    options: EyeClFieldConfig['options'],
    isMultifield?: boolean
  ) => {
    const eyeContactLensesData = contactLensesData?.[eye] ?? null
    const fieldErrors = eyeContactLensesData?.fieldsStatus?.errors ?? []
    const eyeFieldsEnabled = eyeContactLensesData?.fieldsStatus?.enabled ?? false

    return isSelect ? (
      <ContactLensesSelect
        listheading={
          isMultifield ? (
            <div className={clsx(styles.contactLensesListHeadingContainer, browserInUse())}>
              <span className={styles.contactLensesListHeadingItem}>-</span>
              <span className={styles.contactLensesListHeadingItem}>+</span>
            </div>
          ) : null
        }
        id={id}
        eye={eye}
        onSelectValueChange={(eye, id, val) => {
          onClSelectChanged(eye, id, val).then(async data => {
            const { isValid } = validateField(eye, id, data)
            const updatedData = updateFieldsStatus(eye, id, data, isValid, val)
            setFilteredContactLensFieldsConfig(
              updateLensRangeFieldsBySelectedValue(contactLensFieldsConfig, currentProduct, eye, id, val)
            )
            setContactLensesData({ ...contactLensesData, ...updatedData })
            if (formMethods.formState.isDirty) {
              await formMethods.trigger()
            }
          })
        }}
        error={eyeFieldsEnabled ? (fieldErrors?.length ? (fieldErrors.includes(id) ? true : false) : false) : false}
        value={getFieldValue(eyeContactLensesData, id)}
        disabled={!eyeFieldsEnabled}
        key={key}
        options={options}
        multifield={isMultifield}
      />
    ) : (
      <div key={key}>
        <input
          data-testid={`${eye}_${id}`}
          className={clsx(styles.contactLensesInput, styles.readOnly, !eyeFieldsEnabled && styles.disabled)}
          type="text"
          name={id}
          value={getFieldValue(eyeContactLensesData, id)}
        />
      </div>
    )
  }

  const getFieldValue = (eyeContactLensesData, id) => {
    const eye = eyeContactLensesData.x_eye === 'LCON' ? 'left' : 'right'
    const value: string = (eyeContactLensesData && eyeContactLensesData[id]) || ''
    let notAvailable = false
    if (filteredContactLensFieldsConfig && !!value) {
      notAvailable =
        filteredContactLensFieldsConfig[eye]
          .find(field => field.id === id)
          ?.options.find(option => option.text === value)?.notAvailable || false
    }

    return notAvailable ? '' : id === 'x_axis' ? value.padStart(3, '0') : value
  }

  const renderCheckbox = (
    eye: string,
    onCheckboxChanged: (value: boolean, eye: string) => Promise<ContactLensesData>,
    label?: string
  ) => {
    const isEnabled = contactLensesData?.[eye]?.fieldsStatus?.enabled ?? false
    return (
      <StyledFormControlLabel
        control={
          <StyledCheckbox
            data-testid={`${eye}EyeSelector`}
            key={eye}
            checked={isEnabled}
            color="primary"
            onChange={e =>
              onCheckboxChanged(e.target.checked, eye).then(data => {
                setContactLensesData({
                  ...data,
                })
              })
            }
          />
        }
        label={label}
      />
    )
  }

  const onClick = useCallback(async () => {
    if (rxTaxExemption.isEnabled) {
      const prescriptionDetailsFormIsValid = await formMethods.trigger()
      setShowPrescriptionsDetailsError(
        (rxTaxExemption.isEnabled && !prescriptionDetailsFormIsValid && formMethods.formState.isDirty) ||
          Object.keys(formMethods.formState.errors).length > 0
      )
    }

    const data = await validateAllFields()
    setBothEyesDisabled(areBothEyesDisabled(data))
    setContactLensesData(data)
  }, [
    areBothEyesDisabled,
    formMethods,
    rxTaxExemption.isEnabled,
    setBothEyesDisabled,
    setContactLensesData,
    validateAllFields,
  ])

  //TODO: Refactor CL add to cart for to utilize react-hook-form to handle form validation, submission, and other form events.
  useEffect(() => {
    dataRef.current = contactLensesData
    ctaRef.current?.addEventListener('click', onClick)
    stickyBarCtaRef.current?.addEventListener('click', onClick)
    setTotalBoxes && setTotalBoxes(getTotalBoxesCount(contactLensesData))

    // Always validate to ensure field validation flags are up to date
    // Otherwise the initial AddToCart click will be ignored
    validateAllFields().then(async data => {
      dispatch(setAddToCartBusy(false))

      const prescriptionDetailsIsValid =
        (rxTaxExemption.isEnabled && formMethods.formState.isValid) || !rxTaxExemption.isEnabled

      // Update the error state based on current form validity
      setShowPrescriptionsDetailsError(
        (rxTaxExemption.isEnabled && !prescriptionDetailsIsValid && formMethods.formState.isDirty) ||
          Object.keys(formMethods.formState.errors).length > 0
      )

      if (!fieldsAreNotValid(data) && !areBothEyesDisabled(data) && prescriptionDetailsIsValid) {
        const formattedPayload = formatDataForCart(data)
        const prescriptionDetails = formMethods.getValues()

        //TODO Refactor addtocart to use RTK Query mutation directly instead of calling Redux action to update global state in order to trigger api call
        dispatch(toggleAddToCart(true))
        dispatch(
          updateContactLensData({
            contactLensesData: formattedPayload,
            ...(rxTaxExemption.isEnabled && {
              issue: format(prescriptionDetails.issue, 'MM/yyyy'),
              doctor: prescriptionDetails.doctor,
            }),
          })
        )
      } else {
        dispatch(toggleAddToCart(false))
      }
    })

    return () => {
      ctaRef.current?.removeEventListener('click', onClick)
      stickyBarCtaRef.current?.removeEventListener('click', onClick)
    }
  }, [contactLensesData, onEyeCheckBoxValueChanged, doctorName, issueDate])

  const getOrderitemDefaultValues = (defaultQuantity: string): EyeContactLensOverrideMap => {
    try {
      const contactLensDefaultValuesMap: EyeContactLensOverrideMap = {
        left: [],
        right: [],
      }
      contactLensData &&
        forEach(contactLensData, (_, eye) => {
          forEach(contactLensData[eye].data, (value, key) => {
            contactLensDefaultValuesMap[eye].push({
              attribute: key as EyeContactLensAttribute,
              value: value as string,
            })
          })
          contactLensDefaultValuesMap[eye].push({
            attribute: QUANTITY_FIELD_ID,
            value: parseFloat(contactLensData[eye].quantity || defaultQuantity).toString(),
          })
        })
      if (isEditingContactLens) {
        return contactLensDefaultValuesMap
      } else {
        return {
          left: [
            {
              attribute: QUANTITY_FIELD_ID,
              value: defaultQuantity,
            },
          ],
          right: [
            {
              attribute: QUANTITY_FIELD_ID,
              value: defaultQuantity,
            },
          ],
        }
      }
    } catch (e) {
      Log.error('Error on getting contact lenses default data: ' + e)
      return {
        left: [],
        right: [],
      }
    }
  }

  const initializeDefaultQuantityIfWithDiscount = useCallback(async () => {
    if (supplyData && supplyData.length) {
      const updatedLeftEyeValue = await updateFieldValue(
        CL_EYE_LEFT,
        QUANTITY_FIELD_ID,
        toNumber(contactLensData?.left?.quantity || CONTACT_LENS_DEFAULT_QUANTITY).toFixed(0)
      )
      const updatedLeftEyeData = updateFieldsStatus(
        CL_EYE_LEFT,
        QUANTITY_FIELD_ID,
        updatedLeftEyeValue,
        true,
        toNumber(contactLensData?.left?.quantity || CONTACT_LENS_DEFAULT_QUANTITY).toFixed(0)
      ).left

      const updatedRightEyeValue = await updateFieldValue(
        CL_EYE_RIGHT,
        QUANTITY_FIELD_ID,
        toNumber(contactLensData?.right?.quantity || CONTACT_LENS_DEFAULT_QUANTITY).toFixed(0)
      )
      const updatedRightEyeData = updateFieldsStatus(
        CL_EYE_RIGHT,
        QUANTITY_FIELD_ID,
        updatedRightEyeValue,
        true,
        toNumber(contactLensData?.right?.quantity || CONTACT_LENS_DEFAULT_QUANTITY).toFixed(0)
      ).right

      setContactLensesData({
        left: updatedLeftEyeData,
        right: updatedRightEyeData,
      })
      const defaultQuantity = CONTACT_LENS_DEFAULT_QUANTITY
      !isFetchingCart &&
        (getOrderitemDefaultValues(defaultQuantity)?.left.length > 0 ||
          getOrderitemDefaultValues(defaultQuantity)?.right.length > 0) &&
        setDefaultClValues({
          left: [...getOrderitemDefaultValues(defaultQuantity)?.left],
          right: [...getOrderitemDefaultValues(defaultQuantity)?.right],
        })
    }

    //Forcing the eye to be disabled when editing
    if (contactLensData && isEditingContactLens) {
      forEach(contactLensData, (_, eye) => {
        if (!contactLensData[eye]?.quantity) {
          onEyeCheckBoxValueChanged(false, eye).then(data => {
            setContactLensesData({
              ...data,
            })
          })
        }
      })
    }
  }, [supplyData, contactLensData, currentProduct])

  useEffect(() => {
    initializeDefaultQuantityIfWithDiscount()
  }, [supplyData, contactLensData, currentProduct, mySite])

  const disableAllFields =
    soldOutStatus !== PRODUCT_SOLDOUT_STATUS.INFINITE_INVENTORY && soldOutStatus !== PRODUCT_SOLDOUT_STATUS.NONE

  const errorsContainer = useMemo(() => {
    return (
      <RightColumnErrorContainer>
        {bothEyesDisabled && translate('ContactLenses.Errors.BothEyesDisabled')}
        {(fieldsHaveErrors || showPrescriptionsDetailsError) &&
          !isColorEmpty &&
          translate('ContactLenses.Errors.FieldsWithErrors')}
        {isColorEmpty && translate('ContactLenses.Errors.ColorIsEmptyError')}
        {addContactLensesToCartError && translate('ContactLenses.Errors.PrescriptionNotAvailable')}
        {addSubscriptionToCartError && translate(`Subscriptions.Errors.${addSubscriptionToCartError}`)}
      </RightColumnErrorContainer>
    )
  }, [
    bothEyesDisabled,
    fieldsHaveErrors,
    isColorEmpty,
    addContactLensesToCartError,
    addSubscriptionToCartError,
    showPrescriptionsDetailsError,
  ])

  return (
    <FormProvider {...formMethods}>
      <div className={styles.contactLensesSelectPrescriptionTitle}>
        {translate('ContactLenses.Labels.SelectPrescriptionTitle')}
      </div>
      <div className={clsx(styles.contactLensesDataWrapper, disableAllFields && styles.disabledFields)}>
        <div className={styles.contactLensesDataColumn}>
          <span className={styles.contactLensesDataLabel}>{translate('ContactLenses.Labels.Eye')}</span>
          {labels}
        </div>
        {[CL_EYE_LEFT, CL_EYE_RIGHT].map((eye, i) => {
          return (
            <div className={styles.contactLensesDataColumn} key={`${eye}_${i}`}>
              {renderCheckbox(
                eye,
                onEyeCheckBoxValueChanged,
                translate(`ContactLenses.Labels.${eye.toLowerCase()}Eye`)
              )}
              {filteredContactLensFieldsConfig
                ? filteredContactLensFieldsConfig[eye]
                    ?.filter(fieldConf => fieldConf.id !== QUANTITY_FIELD_ID && fieldConf.id !== COLOR_FIELD_ID)
                    .map(fieldConf => {
                      const clFields: JSX.Element[] = []
                      const { id, select, options, multifield, visible = true } = fieldConf
                      const clField = renderField(
                        `${eye}-${id}`,
                        id,
                        eye,
                        updateFieldValue,
                        select,
                        options,
                        multifield
                      )
                      visible && clFields.push(clField)
                      return clFields
                    })
                : null}
            </div>
          )
        })}
        <div className={styles.contactLensesSeparator} />
        {isColorContacts && (
          <>
            <div className={styles.contactLensesDataColumn}>
              <span className={styles.contactLensesDataLabel}>{translate('ContactLenses.Labels.Color')}</span>
            </div>
            <div className={styles.contactLensesDataColumn}>
              {renderField(
                'left-color',
                COLOR_FIELD_ID,
                CL_EYE_LEFT,
                updateFieldValue,
                true,
                filteredContactLensFieldsConfig?.[CL_EYE_LEFT]?.find(fieldConf => fieldConf.id === COLOR_FIELD_ID)
                  ?.options || [],
                false
              )}
            </div>
            <div className={styles.contactLensesDataColumn}>
              {renderField(
                'right-color',
                COLOR_FIELD_ID,
                CL_EYE_RIGHT,
                updateFieldValue,
                true,
                filteredContactLensFieldsConfig?.[CL_EYE_RIGHT]?.find(fieldConf => fieldConf.id === COLOR_FIELD_ID)
                  ?.options || [],
                false
              )}
            </div>
          </>
        )}
        {!showSubscriptionSection && (
          <>
            <div className={styles.contactLensesDataColumn}>
              <span className={styles.contactLensesDataLabel}>{translate('ContactLenses.Labels.Quantity')}</span>
            </div>
            <div className={styles.contactLensesDataColumn}>
              {renderField(
                'left-quantity',
                QUANTITY_FIELD_ID,
                CL_EYE_LEFT,
                updateFieldValue,
                true,
                filteredContactLensFieldsConfig
                  ? filteredContactLensFieldsConfig[CL_EYE_LEFT]?.find(fieldConf => fieldConf.id === QUANTITY_FIELD_ID)
                      ?.options || []
                  : [],
                false
              )}
            </div>
            <div className={styles.contactLensesDataColumn}>
              {renderField(
                'right-quantity',
                QUANTITY_FIELD_ID,
                CL_EYE_RIGHT,
                updateFieldValue,
                true,
                filteredContactLensFieldsConfig
                  ? filteredContactLensFieldsConfig[CL_EYE_RIGHT]?.find(fieldConf => fieldConf.id === QUANTITY_FIELD_ID)
                      ?.options || []
                  : [],
                false
              )}
            </div>
            <div className={styles.contactLensesSeparator} />
          </>
        )}
      </div>

      <PrescriptionDetailsForm direction="column" />

      <>
        <SubscriptionSelectionOption
          currentProduct={currentProduct}
          productQuantity={getTotalBoxesCount(contactLensesData)}
          isLoading={isLoading}
          soldOutStatus={soldOutStatus}
          isEditingContactLens={isEditingContactLens}
          adjustments={adjustments}
          setAdjustments={setAdjustments}
        >
          <>
            <div className={clsx(styles.cLQuantityWrapper, disableAllFields && styles.disableAllFields)}>
              <div>
                <div className={styles.subscriptionQuantityLabel}>{translate('Subscriptions.Labels.LeftEye')}</div>
                {renderField(
                  'left-quantity',
                  QUANTITY_FIELD_ID,
                  CL_EYE_LEFT,
                  updateFieldValue,
                  true,
                  filteredContactLensFieldsConfig
                    ? filteredContactLensFieldsConfig[CL_EYE_LEFT]?.find(
                        fieldConf => fieldConf.id === QUANTITY_FIELD_ID
                      )?.options || []
                    : [],
                  false
                )}
              </div>
              <div>
                <div className={styles.subscriptionQuantityLabel}>{translate('Subscriptions.Labels.RightEye')}</div>
                {renderField(
                  'right-quantity',
                  QUANTITY_FIELD_ID,
                  CL_EYE_RIGHT,
                  updateFieldValue,
                  true,
                  filteredContactLensFieldsConfig
                    ? filteredContactLensFieldsConfig[CL_EYE_RIGHT]?.find(
                        fieldConf => fieldConf.id === QUANTITY_FIELD_ID
                      )?.options || []
                    : [],
                  false
                )}
              </div>
            </div>
          </>
        </SubscriptionSelectionOption>
      </>
      {errorsContainer}
    </FormProvider>
  )
}
