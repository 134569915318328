import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { v4 as uuid } from 'uuid'
import { OmniChannelLink } from './components/OmniChannelLink'
import { StyledTooltip } from '@components/UI'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { IPlacement, IPlacementItem } from '@typesApp/cmsPlacement/Placement'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import styles from './styles/OmnichannelCarousel.module.scss'
import clsx from 'clsx'
import HTMLReactParser from 'html-react-parser'

type OmniChannelCarouselProps = {
  placements: IPlacement<IPlacementItem>[]
}
type OmniChannelItemProps = {
  item: ILXTeaser
  index: number
  backgroundColor: string | undefined
}

const OmniChannelItem = ({ item, index, backgroundColor }: OmniChannelItemProps) => (
  <div className={clsx(styles.swiperContainer, backgroundColor)}>
    <OmniChannelLink
      actionSettings={item.teaserLXCallToActionSettings[0]}
      dataElementId={`X_X_1Placement_OmniChannel${index}_CTA`}
      icon={item.teaserIcon}
      title={item.teaserTitle4}
      text={item.teaserText1}
    />
    {item.teaserText2 && (
      <StyledTooltip
        contentVariant={{
          type: 'textonly',
          body: HTMLReactParser(item.teaserText2.replace(/<.*?>/gi, '')).toString(),
        }}
        colorVariant={'dark'}
      >
        <div className={styles.tooltip}>
          <SVGIcon library="validation" name="info" />
        </div>
      </StyledTooltip>
    )}
  </div>
)

export const OmniChannelCarousel = (props: OmniChannelCarouselProps) => {
  const { placements } = props
  const omnichannelData =
    placements?.find(x => x.name === 'PDP_omnichannel_carousel') ?? ({} as IPlacement<IPlacementItem>)
  const omnichannelItems = omnichannelData?.items as ILXTeaser[]
  const showCarousel = omnichannelItems?.some(item => {
    const { teaserTitle4, teaserText1, teaserText2 } = item as ILXTeaser
    return teaserTitle4 || teaserText1 || teaserText2
  })

  if (!showCarousel) {
    return null
  }

  return omnichannelItems?.length > 1 ? (
    <div className={styles.wrapper}>
      <Swiper
        className={styles.swiperWrapper}
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        navigation={true}
        modules={[Pagination, Navigation]}
      >
        {omnichannelItems?.map((item, index) => (
          <SwiperSlide key={`dc-info__${uuid()}`}>
            <OmniChannelItem item={item} index={index} backgroundColor={omnichannelData.backgroundColor} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : (
    <div className={styles.wrapper}>
      {omnichannelItems?.map((item, index) => (
        <OmniChannelItem key={uuid()} item={item} index={index} backgroundColor={omnichannelData.backgroundColor} />
      ))}
    </div>
  )
}
